/* global gettext google */


// #############################################################################
// REGISTRATION

(function() {

  // Hide fields

  var $representation = $('[id$="representation"]').parents('.x-form-item');
  var $representation_email = $('[id$="representation_email"]').parents('.x-form-item');
  var $escort = $('[type="radio"][id*="-escort_"]').parents('.x-form-item');
  var $escort_count = $('[id$="escort_count"]').parents('.x-form-item');
  var $agent_items = $('[data-agent-items]');

  $representation.hide();
  $representation_email.hide();
  $escort.hide();
  $escort_count.hide();

  // Clone and move inputs

  $escort.each(function(index) {
    var $agent_item = $escort.eq(index).parents('[data-agenda-item]');
    var $escort_parent = $('[type="radio"][id*="-escort_"]', $agent_item).parents('.x-form-item');
    var $escort_count_parent = $('[id$="escort_count"]', $agent_item).parents('.x-form-item');

    var $label = $agent_item.find('.x-form-radio-wrapper label').first();
    var $escort_clone = $escort_parent.clone();
    var $escort_count_clone = $escort_count_parent.clone();

    var $agent_items_2 = $('[data-agent-items]', $agent_item);
    var $agent_items_clone  = $agent_items_2.clone();

    $label.after(
      $escort_clone.addClass('x-escort-wrapper')
    );

    $escort_clone.after(
      $escort_count_clone.addClass('x-escort-count-wrapper')
    );

    $escort_count_clone.after(
      $agent_items_clone.addClass('x-agent-items-wrapper').hide()
    );

    $escort_parent.remove();
    $escort_count_parent.remove();
  });

  $agent_items.remove();
  $('[data-escort-item').hide();

  // Status

  function checkAgendaDate($agenda_item) {
    var current_start_time = $agenda_item.attr('data-agenda-start');
    var current_end_time = $agenda_item.attr('data-agenda-end');
    var $current_start_time = new Date(current_start_time);
    var $current_end_time = new Date(current_end_time);

    $('[data-agenda-item]').each(function() {
      var $this = $(this);
      var start_time= $this.attr('data-agenda-start');
      var end_time = $this.attr('data-agenda-end');

      var $start_time = new Date(start_time);
      var $end_time = new Date(end_time);

      if ($current_start_time >= $start_time && $current_end_time <= $end_time) {
        if ($this.attr('data-agenda-item') != $agenda_item.attr('data-agenda-item')) {
          $this.find('[name$="-status"][value=2]').prop('checked', true);
        }
      }
    });
  }

  $('[name$="-status"]').on('change', function() {
    var $this = $(this);
    var $agenda_item = $this.parents('[data-agenda-item]');
    var name = $this.attr('name');

    $representation = $agenda_item.find('[id$="representation"]').parents('.x-form-item');
    $representation_email = $agenda_item.find('[id$="representation_email"]').parents('.x-form-item');
    $escort = $agenda_item.find('[type="radio"][id*="-escort_"]').parents('.x-escort-wrapper');

    var $escort_inputs = $('input[type="radio"]', $escort);

    if ($('[name="' + name + '"]').eq(2).is(':checked')) {
      $representation.show();
      $representation_email.show();
    }
    else if ($this.val() == 1 || $this.val() == 3) {
      $representation.hide();
      $representation_email.hide();
      $escort_inputs.attr('required', 'required');
      $escort.show();

      checkAgendaDate($agenda_item);
    }
    else {
      var $escort_checkbox = $agenda_item.find('[id$="escort"]');
      var $escort_count_select = $agenda_item.find('[id$="escort_count"]');

      $escort_checkbox.prop('checked', false);

      $escort_count = $escort_count_select.parents('.x-escort-count-wrapper');
      $escort_count.hide();
      $escort_count_select.val(0);

      $agenda_item.find('[data-escort-item]').hide();

      $representation.hide();
      $representation_email.hide();

      $escort_inputs.prop('checked', false).removeAttr('required');
      $escort.hide();
    }
  });

  // Escort

  $('[name$="-escort"]').on('change', function() {
    var $this = $(this);
    var $agenda_item = $this.parents('[data-agenda-item]');
    var $escort_count_select = $agenda_item.find('[id$="escort_count"]');

    $escort_count = $escort_count_select.parents('.x-escort-count-wrapper');
    $agent_items = $escort_count.next('.x-agent-items-wrapper')

    if ($this.val() == 1) {
      $agenda_item.find('[data-escort-item="1"]').show();
      $escort_count.show();
      $agent_items.show();
      $escort_count_select.val(1);

      $('[value="0"]', $escort_count_select).attr('hidden', 'hidden');
    }
    else {
      $agenda_item.find('[data-escort-item]').hide();
      $escort_count.hide();
      $agent_items.hide();
      $escort_count_select.val('');

      $('[value="0"]', $escort_count_select).removeAttr('hidden');
    }
  });

  // Escort count

  $('[name$="-escort_count"]').on('input', function() {
    var $this = $(this);
    var $agenda_item = $this.parents('[data-agenda-item]');

    var escort_count = parseInt($this.val());
    var max_escort_count = parseInt($this.find('option:last').val());

    for (var i1 = 1; i1 < escort_count + 1; i1++) {
      $agenda_item.find('[data-escort-item="' + i1 + '"]').show();
    }

    for (var i2 = escort_count + 1; i2 <= max_escort_count; i2++) {
      $agenda_item.find('[data-escort-item="' + i2 + '"]').hide();
    }
  });
})();


// #############################################################################
// REGISTRATION: GOOGLE MAPS

(function() {
  var $google_maps = $('.x-google-map-wrapper');

  if ($google_maps.length === 0) {
    return;
  }

  function init_map($google_map) {
    var $map = $google_map.find('[data-x-google-map]');

    var $lat_lng = {
      lat: parseFloat($google_map.attr('data-x-lat')),
      lng: parseFloat($google_map.attr('data-x-lng')),
    };

    var $gmap = new google.maps.Map(document.getElementById($map.attr('id')), {
      center: $lat_lng,
      zoom: 16,
    });

    new google.maps.Marker({
      map: $gmap,
      position: $lat_lng,
      title: $google_map.attr('data-x-location'),
    });
  }

  $google_maps.each(function(index) {
    init_map($google_maps.eq(index));
  });

  // Toggle map

  var $google_maps_btn = $('[data-x-toggle-google-map]');

  $google_maps_btn.on('click', function() {
    var $this = $(this);
    var href = $this.attr('href');
    var $wrapper = $(href);

    $google_maps.addClass('x-hidden');
    $google_maps_btn.attr('aria-expanded', 'false');

    if ($wrapper.hasClass('x-hidden')) {
      $wrapper.removeClass('x-hidden');
      $this.attr('aria-expanded', 'true');
    }
    else {
      $wrapper.addClass('x-hidden')
    }

    return false;
  });
})();


// #############################################################################
// EVENTS

(function() {
  var $copy_event = $('[data-copy-event]');

  $copy_event.on('click', function() {
    var result = confirm(gettext('Do you really want to copy the event?'));

    if (!result) {
      return false;
    }
  });
})();
